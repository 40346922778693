import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Link } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isEmpty, isNil } from 'lodash';
import toast from 'react-hot-toast';
import * as yup from 'yup';

import { getAxiosErrorMessage } from '@inspiren-monorepo/util-axios';
import { isUsernameOrg } from '@inspiren-monorepo/util-users';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Battery } from './components/Battery';
import { BeaconModalForm } from './components/BeaconModalForm';
import { BulkUploadCSV } from './components/BulkUploadCSV';
import { createBeacon } from './data-access/createBeacon';
import { updateBeacon } from './data-access/updateBeacon';

import { useIsAdmin } from '../../../../hooks/useIsAdmin';
import OrgSelector from '../../components/OrgSelector';
import { TableBase } from '../../components/TableBase';
import { AdminTableWrapper } from '../../components/TableBase/AdminTableWrapper';
import { getBeacons } from '../../data-access/getBeacons';
import { getOrgs } from '../../data-access/getOrgs';
import { formatNoValue } from '../helpers/formatNoValue';
import { formatUnassigned } from '../helpers/formatUnassigned';
import { noValueClassName } from '../helpers/noValueClassName';

import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../../modals/FormModalBase';
import type { DataFields, FieldTypes } from '../../types/DataFields';

/**
 * When we import beacons we only receive few last digits so we need to add prefix
 */
const BEACON_PREFIX = 'a0224eb0';

interface BeaconFields extends FieldTypes {
  id: string;
  assignedTo?: string;
  assignedToEmail?: string;
  assignedToUsername?: string;
  email?: string;
  firstName?: string;
  lastSeen?: string;
  latest: number;
  buildingName: string;
  lastSeenInRoom?: string;
  org: string;
  battery?: string;
}

export const BeaconsTable = () => {
  const awsOrg = import.meta.env.VITE_ORG_ID;
  const queryClient = useQueryClient();

  const [selectedOrg, setSelectedOrg] = useState<string | undefined>(awsOrg);

  const { isLoading: orgsLoading, data: orgs } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  const {
    isLoading: loading,
    data: beacons,
    isError,
  } = useQuery({
    queryKey: ['beacons', selectedOrg],
    queryFn: () => getBeacons(selectedOrg),
  });

  const fields = useMemo(() => {
    const innerFields: DataFields<BeaconFields> = [
      {
        field: 'id',
        label: 'ID',
        width: 250,
        editType: 'text',
        editable: false,
        initialValue: BEACON_PREFIX,
        schema: yup
          .string()
          .required('You must provide an ID')
          .when('org', ([org], schema) => {
            if (orgs?.find((o) => o.id === org)?.beaconsFourDigitValidation) {
              return schema.length(
                BEACON_PREFIX.length + 4,
                `ID should have ${BEACON_PREFIX.length + 4} characters.`,
              );
            }

            return schema;
          })
          .matches(
            new RegExp(`^${BEACON_PREFIX}\\S+$`, 'i'),
            `ID should start with ${BEACON_PREFIX} prefix.`,
          )
          .matches(/^\S+$/i, "ID can't contain spaces.")
          .matches(
            /^[\da-z]+$/,
            'ID can only include lower-case letter and number characters',
          ),
        renderCell: ({ value }) => (
          <Link href={`/admin/beacons/${value}`}>{value}</Link>
        ),
      },
      {
        field: 'assignedTo',
        label: 'Assigned To',
        width: 300,
        editType: 'text',
        renderCell: ({ value, row }) => {
          if (!isEmpty(value)) {
            return isUsernameOrg(awsOrg)
              ? row.assignedToUsername
              : row.assignedToEmail;
          }

          return <span className='no-value'>unassigned</span>;
        },
      },
      {
        field: 'assignedToUserFullName',
        label: 'Name',
        width: 150,
        editType: 'text',
        valueFormatter: formatUnassigned,
        cellClassName: noValueClassName,
      },
      {
        field: 'battery',
        label: 'Battery',
        width: 90,
        editType: 'text',
        editable: false,
        renderCell: ({ value }) =>
          isNil(value) ? '-' : <Battery value={value} />,
      },
      {
        field: 'lastSeen',
        label: 'Last Seen At',
        width: 200,
        editType: 'text',
        valueFormatter: formatNoValue,
      },
      {
        field: 'buildingName',
        label: 'Building',
        width: 200,
        editable: false,
        valueFormatter: formatNoValue,
      },
      {
        field: 'lastSeenInRoom',
        label: 'Last Seen In Room',
        width: 300,
        editType: 'text',
        valueFormatter: formatNoValue,
      },
    ];

    return innerFields;
  }, [selectedOrg, orgs]);

  const { isAdmin } = useIsAdmin();

  const selectedOrganization = useMemo(
    () => (orgs || []).find((org) => org.id === selectedOrg),
    [selectedOrg, orgs],
  );

  const handleOrgChange = useCallback(
    (
      _e: React.ChangeEvent<object>,
      newValue: AdminTypes.OrganizationDto | null,
    ) => {
      setSelectedOrg(newValue?.id);
    },
    [],
  );

  const handleEditSubmit: OnSubmitFormModal<BeaconFields> = useCallback(
    async (beacon) => {
      try {
        await updateBeacon(beacon.org, beacon.id, beacon.assignedTo);

        await queryClient.invalidateQueries({
          queryKey: ['beacons', selectedOrg],
        });

        toast.success('Beacon updated successfully.');
      } catch (error) {
        const message =
          getAxiosErrorMessage(error) ??
          `Error updating beacon ${error ? `: ${error}` : ''}`;

        toast.error(message);
      }
    },
    [selectedOrg],
  );

  const handleAddSubmit: OnSubmitFormModal<BeaconFields> = useCallback(
    async (beacon) => {
      try {
        await createBeacon(selectedOrg, beacon.id, beacon.assignedTo);

        await queryClient.invalidateQueries({
          queryKey: ['beacons', selectedOrg],
        });

        toast.success('Beacon created successfully.');
      } catch (error) {
        const message = getAxiosErrorMessage(error) ?? 'Could not add beacon';

        toast.error(message);
      }
    },
    [selectedOrg],
  );

  const renderModal: RenderFormModal<BeaconFields> = useCallback(
    ({ control, type }) => (
      <BeaconModalForm
        control={control}
        type={type}
        defaultOrg={selectedOrg}
        beacons={beacons}
        isAdmin={isAdmin}
      />
    ),
    [selectedOrg, beacons],
  );

  return (
    <AdminTableWrapper>
      {isAdmin && (
        <OrgSelector
          orgs={orgs ?? []}
          loading={orgsLoading}
          value={selectedOrganization || null}
          onChange={handleOrgChange}
        />
      )}
      <TableBase<BeaconFields>
        key={`beacons-${selectedOrg}`}
        itemName='Beacon'
        fields={fields}
        data={beacons}
        loading={loading}
        modalLoading={orgsLoading}
        error={isError}
        onEditSubmit={handleEditSubmit}
        onAddSubmit={handleAddSubmit}
        extraActionButtons={isAdmin && <BulkUploadCSV org={selectedOrg} />}
        renderModal={renderModal}
        disableAddButton={!isAdmin}
      />
    </AdminTableWrapper>
  );
};
