import { formatDuration } from 'date-fns';

import type { Duration, Locale } from 'date-fns';

const formatMap = {
  xDays: 'd',
  xHours: 'hr',
  xMinutes: 'min',
  xSeconds: 'sec',
};

export const formatDurationConcise = (duration: Duration) => {
  if (!duration) return;

  const conciseLocale: Locale = {
    formatDistance: (token: keyof typeof formatMap, count: number) =>
      `${count} ${formatMap[token] ?? ''}`,
  };

  return formatDuration(duration, {
    format: ['days', 'hours', 'minutes', 'seconds'],
    locale: conciseLocale,
  });
};
