import type React from 'react';
import { Stack } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  type GridToolbarProps,
} from '@mui/x-data-grid-pro';

import AddModal from '../../../modals/AddModal';

import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../../../modals/FormModalBase';
import type { DataFields, FieldTypes } from '../../../types/DataFields';

export interface ToolbarProps<F extends FieldTypes> extends GridToolbarProps {
  itemName: string;
  fields: DataFields<F>;
  setPanelAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  onAddSubmit?: OnSubmitFormModal<F>;
  onAddOpen?: () => void;
  addLoading?: boolean;
  addError?: string | null;
  showExportMenu?: boolean;
  extraActionButtons?: React.ReactNode;
  extraTableOptions?: React.ReactNode;
  disableAddButton?: boolean;
  showAddButton?: boolean;
  renderModal?: RenderFormModal<F>;
}

export const Toolbar = <F extends FieldTypes>({
  itemName,
  fields,
  setPanelAnchorEl,
  onAddSubmit,
  onAddOpen,
  addLoading = false,
  addError = null,
  showExportMenu = true,
  extraActionButtons,
  extraTableOptions,
  disableAddButton,
  showAddButton = true,
  renderModal,
}: ToolbarProps<F>) => (
  <GridToolbarContainer>
    <Stack
      direction='row'
      flexWrap='wrap'
      width='100%'
      justifyContent='space-between'
      mb={1}
      gap={1}
    >
      <Stack direction='row' gap={1} alignItems='center'>
        {showAddButton && (
          <AddModal
            itemName={itemName}
            fields={fields}
            onSubmit={onAddSubmit}
            onOpen={onAddOpen}
            loading={addLoading}
            error={addError}
            disabled={disableAddButton}
            renderModal={renderModal}
          />
        )}
        {extraActionButtons}
      </Stack>
      <Stack direction='row' ml='auto' gap={1}>
        {extraTableOptions}
        <GridToolbarColumnsButton ref={setPanelAnchorEl} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {showExportMenu && (
          <GridToolbarExport
            csvOptions={{ fileName: `${itemName}Data` }}
            printOptions={{ disableToolbarButton: true }}
          />
        )}
      </Stack>
    </Stack>
  </GridToolbarContainer>
);
