import type { BeaconHistory } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../../../utility/client';

export async function getBeaconHistory(id: string) {
  const response = await Api.get<BeaconHistory[]>(
    `/admin/v1/beacons/${id}/history`,
  );

  return response.data;
}
