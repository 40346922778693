import { format } from 'date-fns';

import {
  dateTimeFormatNoYear,
  timeFormat,
} from '../../../../utility/helpers/time';

export const formatDateTime = (value: Date | null, showDate: boolean) => {
  const formatString = showDate ? dateTimeFormatNoYear : timeFormat;
  return value ? format(value, formatString) : '';
};
