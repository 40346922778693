import { isBefore, subDays } from 'date-fns';
import { isNil } from 'lodash';

import type { EventReviewTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { clearUrlParams } from '../helpers/clearUrlParams';
import { updateUrlParams } from '../helpers/updateUrlParams';
import { useEventReviewStoreShallow } from '../store/EventReviewStore';

const setDatesWithGuard = (
  startDate: Date | null,
  endDate: Date | null,
  selectedUnit: EventReviewTypes.Unit | null,
  setStartDate: (newStartDate: Date | null) => void,
  setEndDate: (newEndDate: Date | null) => void,
) => {
  let safeStartDate: Date | null = startDate;
  let safeEndDate: Date | null = endDate;

  // Reset end date if it's before start date
  if (startDate && endDate && isBefore(endDate, startDate)) {
    safeEndDate = null;
  }

  // Reset dates if outside of the deletion range of the unit
  if (
    selectedUnit?.imageExp &&
    selectedUnit?.eventExp &&
    (startDate || endDate)
  ) {
    const timeWindowStart = subDays(
      new Date(),
      Math.max(selectedUnit.imageExp, selectedUnit.eventExp),
    );

    if (
      (startDate && isBefore(startDate, timeWindowStart)) ||
      (endDate && isBefore(endDate, timeWindowStart))
    ) {
      safeStartDate = null;
      safeEndDate = null;
    }
  }

  setStartDate(safeStartDate);
  setEndDate(safeEndDate);

  return { safeStartDate, safeEndDate };
};

export const useUpdateSelection = () => {
  const {
    selectedOrg,
    selectedUnit,
    selectedRoom,
    startDate,
    endDate,
    setSelectedOrg,
    setSelectedUnit,
    setSelectedRoom,
    setStartDate,
    setEndDate,
  } = useEventReviewStoreShallow([
    'selectedOrg',
    'selectedUnit',
    'selectedRoom',
    'startDate',
    'endDate',
    'setSelectedOrg',
    'setSelectedUnit',
    'setSelectedRoom',
    'setStartDate',
    'setEndDate',
  ]);

  const updateSelectedOrg = (newOrg: EventReviewTypes.Organization | null) => {
    setSelectedRoom(null);
    setSelectedUnit(null);
    setSelectedOrg(newOrg);
    clearUrlParams();
  };

  const updateSelectedUnit = (newUnit: EventReviewTypes.Unit | null) => {
    setSelectedRoom(null);
    setSelectedUnit(newUnit);
    clearUrlParams();
  };

  const updateSelectedRoom = (newRoom: EventReviewTypes.Room | null) => {
    setSelectedRoom(newRoom);

    if (isNil(newRoom)) {
      clearUrlParams();
    } else {
      updateUrlParams(selectedOrg, selectedUnit, newRoom, startDate, endDate);
    }
  };

  const updateStartDate = (newStartDate: Date | null) => {
    const { safeStartDate, safeEndDate } = setDatesWithGuard(
      newStartDate,
      endDate,
      selectedUnit,
      setStartDate,
      setEndDate,
    );

    if (isNil(safeStartDate) || isNil(safeEndDate)) {
      clearUrlParams();
    } else {
      updateUrlParams(
        selectedOrg,
        selectedUnit,
        selectedRoom,
        safeStartDate,
        safeEndDate,
      );
    }
  };

  const updateEndDate = (newEndDate: Date | null) => {
    const { safeStartDate, safeEndDate } = setDatesWithGuard(
      startDate,
      newEndDate,
      selectedUnit,
      setStartDate,
      setEndDate,
    );

    if (isNil(safeStartDate) || isNil(safeEndDate)) {
      clearUrlParams();
    } else {
      updateUrlParams(
        selectedOrg,
        selectedUnit,
        selectedRoom,
        safeStartDate,
        safeEndDate,
      );
    }
  };

  const updateStartAndEndDate = (
    newStartDate: Date | null,
    newEndDate: Date | null,
  ) => {
    const { safeStartDate, safeEndDate } = setDatesWithGuard(
      newStartDate,
      newEndDate,
      selectedUnit,
      setStartDate,
      setEndDate,
    );

    if (isNil(safeStartDate) || isNil(safeEndDate)) {
      clearUrlParams();
    } else {
      updateUrlParams(
        selectedOrg,
        selectedUnit,
        selectedRoom,
        safeStartDate,
        safeEndDate,
      );
    }
  };

  return {
    updateSelectedOrg,
    updateSelectedUnit,
    updateSelectedRoom,
    updateStartDate,
    updateEndDate,
    updateStartAndEndDate,
  };
};
