import { useCallback, useState } from 'react';
import { Alert, Button, Link, Tooltip } from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { isAxiosError } from 'axios';
import { format } from 'date-fns';
import { useConfirm } from 'material-ui-confirm';
import toast from 'react-hot-toast';

import { getAxiosErrorMessage } from '@inspiren-monorepo/util-axios';

import { GenerateExternalApiKeyModal } from './components/GenerateExternalApiKeyModal';
import { SecureToken } from './components/SecureToken';
import { useDeleteExternalApiKey } from './hooks/useDeleteExternalApiKey';
import { useExternalApiKeys } from './hooks/useExternalApiKeys';

import { TableBase } from '../../components/TableBase';

import type { DataField, DataFields } from '../../types/DataFields';

type ExternalApiFields = {
  id: string;
  secureApiKey: string;
  expiresAt: string;
  createdBy: string;
  createdByName: string;

  delete: never; // delete action
};

const fields: DataFields<ExternalApiFields> = [
  {
    field: 'id',
    label: 'ID',
    width: 'hidden',
  },
  {
    field: 'secureApiKey',
    label: 'API Key (redacted)',
    width: 250,
    editType: 'text',
    editable: false,
    renderCell: ({ value }) => <SecureToken value={value} />,
  },
  {
    field: 'expiresAt',
    label: 'Expiration',
    width: 200,
    editable: false,
    valueFormatter: (value) => format(new Date(value), 'MM/dd/yyyy h:mm aaa'),
  },
  {
    field: 'createdByName',
    label: 'Created By',
    width: 200,
    editable: false,
  },
];

const ExternalApiTable = () => {
  const zuiApiUrl = import.meta.env.VITE_ZUI_API_URL;

  const [modalOpen, setModalOpen] = useState(false);
  const confirm = useConfirm();
  const { data: externalApiKeys, isLoading } = useExternalApiKeys();
  const { mutateAsync } = useDeleteExternalApiKey();

  const handleDeleteClick = useCallback(
    (id: string, token: string) => async () => {
      try {
        await confirm({
          title: 'Caution',
          description: (
            <>
              <div>Are you sure you want to delete this API Key?</div>
              <div>
                API Key: <b>{token}</b>
              </div>
              <div>
                Once deleted, it will no longer grant access to the API.
              </div>
            </>
          ),
          confirmationText: 'Delete',
          confirmationButtonProps: {
            color: 'delete',
          },
        });

        await mutateAsync(id);
      } catch (error) {
        if (isAxiosError(error)) {
          const message =
            getAxiosErrorMessage(error) || 'Could not delete the secure token';

          toast.error(`Error: ${message}`);
        }
      }
    },
    [confirm, mutateAsync],
  );

  const deleteItem: DataField<ExternalApiFields> = {
    field: 'delete',
    type: 'actions',
    label: '',
    width: 50,
    renderCell: ({ id, row }) => (
      <GridActionsCellItem
        icon={<DeleteIcon color='delete' />}
        label='Delete'
        onClick={handleDeleteClick(id as string, row.secureApiKey)}
      />
    ),
  };

  return (
    <>
      <Alert severity='info' sx={{ marginBottom: '0.5rem' }}>
        You can use this API Key as your Authentication Key for External API.
        For detailed guidance, please refer to the{' '}
        <Link target='_blank' href={`${zuiApiUrl}/external/api-docs`}>
          API Documentation
        </Link>
        .
      </Alert>
      <TableBase<ExternalApiFields>
        itemName='API Key'
        fields={[...fields, deleteItem]}
        data={externalApiKeys?.data ?? []}
        loading={isLoading}
        showAddButton={false}
        disableEditing
        extraActionButtons={
          <Tooltip title='Generate API Key' placement='top'>
            <Button
              variant='contained'
              size='small'
              startIcon={<AddIcon />}
              onClick={() => setModalOpen(true)}
            >
              Generate API Key
            </Button>
          </Tooltip>
        }
      />
      {modalOpen && (
        <GenerateExternalApiKeyModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
};

export default ExternalApiTable;
