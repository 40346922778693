import { useMemo } from 'react';
import {
  TransferWithinAStation as BeaconIcon,
  Computer as ComputerIcon,
  Smartphone as SmartphoneIcon,
} from '@mui/icons-material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { camelCase, capitalize, isEmpty } from 'lodash';

import { ALL_ALERTS, FALL_RISK_OPTIONS } from '@inspiren-monorepo/shared-types';
import { formatAlertType } from '@inspiren-monorepo/util-formatters';

import { formatDurationConcise } from '../../../helpers/formatDurationConcise';
import { gridDurationComparator } from '../../../helpers/gridDurationComparator';
import { useEventReviewStoreShallow } from '../../../store/EventReviewStore';
import { NotifsSentModal } from '../../NotifsSentModal';
import TableRowMenu from '../../TableRowMenu';
import { TimeLink } from '../../components/TimeLink';
import { formatDateTime } from '../../helpers/formatDateTime';

import type { NotificationsRow } from '../types/NotificationsRow';

interface Params {
  showRowMenu: boolean;
  showDate: boolean;
}

export const useNotificationsColumns = ({ showRowMenu, showDate }: Params) => {
  const { selectedRoom, startDate, endDate, images, viewMode } =
    useEventReviewStoreShallow([
      'selectedRoom',
      'startDate',
      'endDate',
      'images',
      'viewMode',
    ]);

  const columns = useMemo(() => {
    const columnDefinitions: (GridColDef<NotificationsRow> | false)[] = [
      {
        field: 'type',
        headerName: 'Type',
        type: 'singleSelect',
        valueOptions: ALL_ALERTS.map((alert) => ({
          value: alert,
          label: formatAlertType(alert),
        })),
        width: 150,
      },
      viewMode === 'admin' && {
        field: 'createdAt',
        headerName: 'Created at',
        type: 'dateTime',
        width: 160,
        valueFormatter: (value) => formatDateTime(value, showDate),
        renderCell: ({ value, formattedValue }) => (
          <TimeLink
            disabled={
              startDate && value && (value < startDate || isEmpty(images))
            }
            value={value}
            formattedValue={formattedValue}
          />
        ),
      },
      viewMode === 'admin' && {
        field: 'status',
        headerName: 'Status',
        type: 'singleSelect',
        valueOptions: ['rejected', 'promoted', 'none'].map((status) => ({
          value: status,
          label: capitalize(status),
        })),
        width: 120,
      },
      viewMode === 'admin' && {
        field: 'label',
        headerName: 'Label',
        type: 'singleSelect',
        valueOptions: ['False Positive', 'False Negative', 'True Positive'].map(
          (status) => ({
            value: camelCase(status),
            label: status,
          }),
        ),
        width: 150,
      },
      {
        field: 'promotedOn',
        headerName: 'Sent at',
        type: 'dateTime',
        width: 160,
        valueFormatter: (value) => formatDateTime(value, showDate),
        renderCell: ({ value, formattedValue }) => (
          <TimeLink
            disabled={
              startDate && value && (value < startDate || isEmpty(images))
            }
            value={value}
            formattedValue={formattedValue}
          />
        ),
      },
      {
        field: 'resolvedAt',
        headerName: 'Resolved at',
        type: 'dateTime',
        width: 160,
        valueFormatter: (value) => formatDateTime(value, showDate),
        renderCell: ({ value, formattedValue }) => (
          <TimeLink
            disabled={(value && endDate && value > endDate) || isEmpty(images)}
            value={value}
            formattedValue={formattedValue}
          />
        ),
      },
      {
        field: 'elapsed',
        headerName: 'Duration',
        description: 'Time between sent and resolved',
        width: 150,
        filterable: false,
        valueFormatter: formatDurationConcise,
        sortComparator: gridDurationComparator,
      },
      {
        field: 'resolvedByName',
        headerName: 'Resolved by',
        width: 250,
        editable: false,
      },
      {
        field: 'source',
        headerName: 'Response via',
        type: 'singleSelect',
        valueOptions: ['web', 'mobile', 'beacon'].map((status) => ({
          value: status,
          label: capitalize(status),
        })),
        width: 120,
        display: 'flex',
        align: 'center',
        renderCell: ({ value }) => {
          if (value === 'beacon') return <BeaconIcon fontSize='small' />;
          if (value === 'web') return <ComputerIcon fontSize='small' />;
          if (value === 'mobile') return <SmartphoneIcon fontSize='small' />;
          return null;
        },
      },
      {
        field: 'received',
        headerName: 'Received by',
        headerAlign: 'left',
        type: 'actions',
        getActions: ({ row }) => [
          <NotifsSentModal
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- There will be a room selected if this is rendered
            roomId={selectedRoom!.domainId}
            notificationId={row.id}
          />,
        ],
        width: 175,
        filterable: false,
        sortable: false,
      },
      {
        field: 'fallRiskLevel',
        headerName: 'Fall sensitivity',
        type: 'singleSelect',
        valueOptions: (['high', 'nightLow', 'low', 'off'] as const).map(
          (value) => ({
            value,
            label: FALL_RISK_OPTIONS[value].label,
          }),
        ),
        width: 150,
      },
      showRowMenu && {
        field: 'Menu',
        type: 'actions',
        getActions: ({ row }) => [
          <TableRowMenu
            id={row.id}
            eventStart={row.promotedOn}
            eventEnd={row.resolvedAt}
          />,
        ],
        width: 40,
        filterable: false,
        sortable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
      },
    ];

    return columnDefinitions.filter(Boolean) as GridColDef<NotificationsRow>[];
  }, [
    startDate,
    endDate,
    showDate,
    images,
    showRowMenu,
    selectedRoom,
    viewMode,
  ]);

  return columns;
};
