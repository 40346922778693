import { Box } from '@mui/material';
import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';

import type { DataField } from '../../../types/DataFields';
import type { RolePermissionFieldTypes } from '../types/RolePermissionFieldTypes';

export const buildPermissionDataField = (
  field: keyof RolePermissionFieldTypes & string,
  label: string,
): DataField<RolePermissionFieldTypes> => ({
  field,
  label,
  width: 150,
  hideOnAdd: true,
  renderCell: ({ row }) => (
    <Box
      height='100%'
      alignItems='center'
      justifyContent='center'
      display='flex'
    >
      {row[field] ? (
        <CheckCircle color='primary' />
      ) : (
        <CheckCircleOutline color='primary' />
      )}
    </Box>
  ),
});
