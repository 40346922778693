import type { GridRowId } from '@mui/x-data-grid-pro';

import type { Importable } from '../../ImportTable/types/importable';
import type { BasestationFields } from '../types/BasestationFieldTypes';

const importProperAssignmentValidator = (
  _uniqueId: GridRowId,
  params: BasestationFields,
  field: keyof BasestationFields,
  _rows: Importable<BasestationFields>[],
) => {
  if (params[field]) {
    return null;
  }

  const assignmentFields: (keyof BasestationFields)[] = [
    'organization',
    'buildingFloorUnit',
    'room',
  ];

  const set = new Set(assignmentFields.map((e) => Boolean(params[e])));

  if (set.size !== 1) {
    return 'You have to assign organization, buildingFloorUnit, room or un-assign';
  }

  return null;
};

export default importProperAssignmentValidator;
