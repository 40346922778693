import type { DOMAttributes } from 'react';
import { forwardRef } from 'react';
import type { GridRenderEditCellParams } from '@mui/x-data-grid-pro';
import { useGridApiContext } from '@mui/x-data-grid-pro';

import SelectLevelAccessBuildings from './SelectLevelAccessBuildings';

type Props = GridRenderEditCellParams<string[]> &
  Pick<
    DOMAttributes<HTMLDivElement>,
    | 'onBlur'
    | 'onFocus'
    | 'onMouseLeave'
    | 'onMouseOver'
    | 'onTouchEnd'
    | 'onTouchStart'
  > & {
    org: string;
  };

const ImportBuildingsAutocomplete = forwardRef((params: Props, ref) => {
  const apiRef = useGridApiContext();

  const {
    id,
    org,
    value,
    error: validationError,
    disabled,
    field,
    onBlur,
    onFocus,
    onMouseLeave,
    onMouseOver,
    onTouchEnd,
    onTouchStart,
  } = params;

  return (
    <SelectLevelAccessBuildings
      ref={ref}
      error={validationError}
      id={id?.toString()}
      value={value}
      disabled={disabled || !org}
      onChange={(newValue) => {
        apiRef.current.setEditCellValue({
          id,
          field,
          value: newValue,
          debounceMs: 200,
        });
      }}
      inputSx={{
        padding: 0,
        margin: 0,
      }}
      onBlur={onBlur}
      onFocus={onFocus}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      onTouchEnd={onTouchEnd}
      onTouchStart={onTouchStart}
    />
  );
});

export default ImportBuildingsAutocomplete;
