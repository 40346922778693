import { useMemo } from 'react';
import { Box, Typography, lighten, useTheme, Stack } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import * as Sentry from '@sentry/react';
import { isNil, sortBy } from 'lodash';

import { DataGridBase } from './DataGridBase';
import { MarkSwitch } from './MarkSwitch';
import { useRoomActivityColumns } from './RoomActivity/hooks/useRoomActivityColumns';
import TableMenu from './TableMenu';
import { useIsRowActive } from './hooks/useIsRowActive';

import { sendAmpEvent } from '../../../utility/amplitude';
import { useRoomState } from '../hooks/useRoomState';
import { useStaffEvents } from '../hooks/useStaffEvents';
import { useUserEvents } from '../hooks/useUserEvents';
import { useEventReviewStoreShallow } from '../store/EventReviewStore';

interface Props {
  showDate?: boolean;
  showRowMenu: boolean;
}

const EventsTable = ({ showDate = false, showRowMenu }: Props) => {
  const { images, showStaffEventMarks, setShowStaffEventMarks, viewMode } =
    useEventReviewStoreShallow([
      'images',
      'showStaffEventMarks',
      'setShowStaffEventMarks',
      'viewMode',
    ]);

  const columns = useRoomActivityColumns({ showRowMenu, showDate });

  const { data: events, isLoading, isError } = useStaffEvents();

  const {
    data: userEvents,
    isLoading: userEventsIsLoading,
    isError: userEventsIsError,
  } = useUserEvents();

  const {
    data: roomState,
    isLoading: roomStateIsLoading,
    isError: roomStateIsError,
  } = useRoomState();

  const theme = useTheme();

  const activeRowBackgroundColor = lighten(theme.palette.staffEvent.light, 0.6);

  const rows = useMemo(
    () =>
      sortBy(
        [...events, ...userEvents, ...(viewMode === 'admin' ? roomState : [])],
        'time',
      ),
    [events, userEvents, roomState, viewMode],
  );

  const eventsExist = useMemo(
    () =>
      (events && events.length > 0) ||
      (userEvents && userEvents.length > 0) ||
      (roomState && roomState.length > 0),
    [events, userEvents, roomState],
  );

  const showToggle = useMemo(
    () => eventsExist && !isNil(images) && images.length > 0,
    [eventsExist, images],
  );

  const apiRef = useGridApiRef();

  const isRowActive = useIsRowActive();

  return (
    <Stack sx={{ height: '100%', minHeight: 0 }}>
      <Box
        sx={{ display: 'flex', alignItems: 'center' }}
        data-testid='eventreview-eventstable-header'
      >
        <Typography variant='h5' component='h3' p={1.5} mr='auto'>
          Room Activity
        </Typography>
        <MarkSwitch
          id='room-activity-mark-switch'
          checked={showToggle ? showStaffEventMarks : false}
          disabled={!showToggle}
          onChange={() => {
            setShowStaffEventMarks(!showStaffEventMarks);

            sendAmpEvent('Toggled Staff Events', {
              toggle: showStaffEventMarks,
            });
          }}
          customColor={(innerTheme) => innerTheme.palette.staffEvent.main}
        />
        <TableMenu
          id='staff-events'
          exportCsv={apiRef?.current?.exportDataAsCsv}
          disabled={!eventsExist}
        />
      </Box>
      <DataGridBase
        apiRef={apiRef}
        noRowsLabel='No room activity in range'
        rows={rows}
        columns={columns}
        loading={isLoading || userEventsIsLoading || roomStateIsLoading}
        error={isError || userEventsIsError || roomStateIsError}
        getRowClassName={({ row }) =>
          isRowActive(row.time, row.timeOut) ? 'row-active--true' : ''
        }
        activeRowBackgroundColor={activeRowBackgroundColor}
        defaultSortField='time'
      />
    </Stack>
  );
};

export default Sentry.withProfiler(EventsTable);
