import { Controller, useWatch } from 'react-hook-form';

import SelectLevelAccessBuildings from './SelectLevelAccessBuildings';

import type { Control } from 'react-hook-form';

interface Props {
  control: Control;
}

const ControlledSelectLevelAccessBuildings = ({ control }: Props) => {
  const selectedLevelAccess = useWatch({ name: 'levelAccess', control });

  if (selectedLevelAccess !== 'building') {
    return null;
  }

  return (
    <Controller
      name='levelAccessBuildingIds'
      control={control}
      defaultValue={[]}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => (
        <SelectLevelAccessBuildings
          error={error}
          onChange={onChange}
          value={value}
          id={name}
          label='Buildings'
        />
      )}
    />
  );
};

export default ControlledSelectLevelAccessBuildings;
