import { Box } from '@mui/material';
import { Check } from '@mui/icons-material';
import type { GridColDef, GridSingleSelectColDef } from '@mui/x-data-grid-pro';

import type { DataFields, FieldTypes } from '../../../types/DataFields';

export const getGridColDefsFromDataFields = <F extends FieldTypes>(
  fields: DataFields<F>,
): GridColDef<F>[] =>
  fields
    .filter(({ width }) => width !== 'hidden')
    .map(
      ({
        field,
        label,
        width,
        renderCell: renderFn,
        editType,
        options,
        // We use `editable` in the edit modal, so we shouldn't pass it to the GridColDef
        // We don't want the table cell to be editable
        // TODO: Fix when we de-couple the table and the editing system
        editable: _editable,
        ...rest
      }) => {
        let renderCell: GridColDef['renderCell'];

        if (renderFn) {
          renderCell = renderFn;
        } else if (editType === 'boolean') {
          // Display checkmark for `true` and nothing for other values
          renderCell = (params) => {
            if (params.value === true)
              return (
                <Box
                  height='100%'
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                >
                  <Check />
                </Box>
              );
            return '';
          };
        }

        const gridColDef: GridColDef<F> = {
          ...rest,
          field,
          headerName: label,
          width: width as number,
          renderCell,
        };

        if (gridColDef.type === 'singleSelect') {
          const singleSelectGridColDef: GridSingleSelectColDef<F> = {
            ...gridColDef,
            type: 'singleSelect',
            valueOptions: options,
          };

          return singleSelectGridColDef;
        }

        return gridColDef;
      },
    );
