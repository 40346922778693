import { type DOMAttributes, useEffect } from 'react';
import { forwardRef, useMemo } from 'react';
import type { TextFieldProps } from '@mui/material';
import { Autocomplete, TextField } from '@mui/material';
import { keyBy, orderBy } from 'lodash';

import { useUnitOptions } from '../../../../../hooks/useUnitOptions';

import type { LevelAccess } from '../../../../../../types';

interface Props
  extends Pick<
    DOMAttributes<HTMLDivElement>,
    | 'onBlur'
    | 'onFocus'
    | 'onMouseLeave'
    | 'onMouseOver'
    | 'onTouchEnd'
    | 'onTouchStart'
  > {
  id?: string;
  value?: string;
  onChange: (value: string | null) => void;
  error?: unknown;
  disabled?: boolean;
  label?: string;

  selectedLevelAccess?: LevelAccess;
  selectedLevelAccessBuildingIds: string[];

  inputSx?: TextFieldProps['sx'];
}

const SelectUnit = forwardRef((props: Props, ref) => {
  const {
    value,
    onChange,
    id,
    error,
    disabled,
    label,
    inputSx,
    onBlur,
    onFocus,
    onMouseLeave,
    onMouseOver,
    onTouchEnd,
    onTouchStart,
    selectedLevelAccessBuildingIds,
    selectedLevelAccess,
  } = props;

  const { data, loading } = useUnitOptions();

  const unitOptions = useMemo(
    () =>
      (data || []).filter((unit) => {
        if (selectedLevelAccess === 'building') {
          return selectedLevelAccessBuildingIds.includes(unit.buildingId);
        }

        return true;
      }),
    [data, selectedLevelAccess, selectedLevelAccessBuildingIds],
  );

  const units = useMemo(
    () =>
      orderBy(
        unitOptions,
        [
          (item) => item?.buildingDisplayName?.toLowerCase(),
          (item) => item?.displayName?.toLowerCase(),
          (item) => item?.name?.toLowerCase(),
        ],
        ['asc', 'asc', 'asc'],
      ),
    [unitOptions],
  );

  const mappedUnits = useMemo(() => keyBy(units, 'id'), [units]);

  useEffect(() => {
    // const currentUnitBuildingId = value && mappedUnits[value]?.buildingId;
    // if (!currentUnitBuildingId) {
    //   onChange(null);
    // }
    console.log(value);
  }, [value, selectedLevelAccessBuildingIds]);

  return (
    <Autocomplete<string>
      ref={ref}
      id={id}
      disabled={disabled}
      data-testid='unit'
      options={Object.keys(mappedUnits)}
      loading={loading}
      getOptionLabel={(option) => {
        if (option in mappedUnits)
          return `${
            mappedUnits[option].buildingDisplayName
          } ${mappedUnits[option].displayName || mappedUnits[option].name}`;
        return 'error';
      }}
      renderOption={(renderProps, option) => (
        <li {...renderProps}>
          {mappedUnits[option].displayName || mappedUnits[option].name}
        </li>
      )}
      groupBy={(option) =>
        mappedUnits[option].buildingDisplayName ||
        mappedUnits[option].domainId.split('-')[1]
      }
      value={value && value in mappedUnits ? value : null}
      isOptionEqualToValue={(o, v) => o === v}
      onChange={(a, b) => onChange(b)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin='dense'
          error={Boolean(error)}
          sx={inputSx}
        />
      )}
      fullWidth
      onBlur={onBlur}
      onFocus={onFocus}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      onTouchEnd={onTouchEnd}
      onTouchStart={onTouchStart}
    />
  );
});

export default SelectUnit;
