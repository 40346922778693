import { Autocomplete, TextField } from '@mui/material';
import { startCase } from 'lodash';
import { Controller, useWatch } from 'react-hook-form';

import type { RoleMap } from '../../../../../../types';
import type { Control } from 'react-hook-form';

interface Props {
  control: Control;
  roleMap: RoleMap;
}

export const SelectLevelAccess = ({ control, roleMap }: Props) => {
  const roleValue = useWatch({ name: 'role', control });
  return (
    <Controller
      name='levelAccess'
      control={control}
      defaultValue={null}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => (
        <Autocomplete
          id={name}
          data-testid='levelAccess'
          options={['org', 'building', 'unit']}
          value={value || null}
          onChange={(a, b) => onChange(b)}
          isOptionEqualToValue={(o, v) => o === v}
          filterOptions={(options) => options}
          getOptionLabel={(option) => startCase(option)}
          renderOption={(props, option) => (
            <li {...props}>{startCase(option)}</li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Level Access'
              error={Boolean(error)}
              required={roleMap[roleValue]?.templateId !== 'Admin'}
              margin='dense'
            />
          )}
        />
      )}
    />
  );
};
