export const makeRoomId = (
  organization: string,
  buildingFloorUnit: string,
  roomNumber: string,
) => {
  if (!organization || !buildingFloorUnit || !roomNumber) return null;
  return `Room-${organization}-${buildingFloorUnit}-${roomNumber}`;
};

export const makeUnit = (organization: string, buildingFloorUnit: string) => {
  if (!organization || !buildingFloorUnit) return null;
  return `Room-${organization}-${buildingFloorUnit}`;
};
