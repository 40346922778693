import type { GridRowId } from '@mui/x-data-grid-pro';

import type { Importable } from '../../ImportTable/types/importable';

type FieldWithOrgAndUnit = {
  organization?: string;
  buildingFloorUnit?: string;
};

const importUniqueRoomValidator =
  (errorMessage: string) =>
  <TFieldTypes extends FieldWithOrgAndUnit>(
    uniqueId: GridRowId,
    params: TFieldTypes,
    field: keyof TFieldTypes,
    rows: Importable<TFieldTypes>[],
  ) => {
    const value = params[field];

    if (!value) {
      return null;
    }

    const { organization, buildingFloorUnit } = params;

    if (
      rows.some(
        (row) =>
          row.$meta.uniqueId !== uniqueId &&
          row.organization === organization &&
          row.buildingFloorUnit === buildingFloorUnit &&
          row[field] === value,
      )
    ) {
      return errorMessage;
    }

    return null;
  };

export default importUniqueRoomValidator;
