import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { format } from 'date-fns';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

import EventsTable from './EventsTable';
import NotificationsTable from './NotificationsTable';

import { useCurrentUser } from '../../../HOC/CurrentUserContextProvider';
import { useSplitPane } from '../hooks/useSplitPane';
import { useEventReviewStoreShallow } from '../store/EventReviewStore';

const DetailsPanel = () => {
  const { startDate, endDate } = useEventReviewStoreShallow([
    'startDate',
    'endDate',
  ]);

  const { user } = useCurrentUser();

  const showDate = useMemo(
    () =>
      (startDate && format(startDate, 'MM/dd/yyyy')) !==
      (endDate && format(endDate, 'MM/dd/yyyy')),
    [startDate, endDate],
  );

  const showRowMenu = useMemo(
    () => user?.ability?.can('view', 'virtual-care.event-review.spotlight'),
    [user],
  );

  const theme = useTheme();
  const splitPane = useSplitPane();

  return (
    <PanelGroup direction='vertical' style={{ height: '75vh' }}>
      <Panel
        defaultSize={50}
        minSize={25}
        data-testid='eventreview-eventstable'
      >
        <EventsTable showDate={showDate} showRowMenu={showRowMenu || false} />
      </Panel>
      <PanelResizeHandle
        disabled={!splitPane}
        style={{ height: '1px', backgroundColor: theme.palette.divider }}
      />
      <Panel
        defaultSize={50}
        minSize={25}
        data-testid='eventreview-notificationstable'
      >
        <NotificationsTable
          showDate={showDate}
          showRowMenu={showRowMenu || false}
        />
      </Panel>
    </PanelGroup>
  );
};

export default DetailsPanel;
