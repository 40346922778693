import type { CompoundSubjectAndAction } from '@inspiren-monorepo/feature-permissions';

type PermissionWithLabel = {
  id: CompoundSubjectAndAction;
  label: string;
}[];

export const globalPermissions: PermissionWithLabel = [
  {
    id: 'global.disable-augi.view',
    label: 'Disable AUGi',
  },
  {
    id: 'global.fall-risk-high-low.view',
    label: 'Set Fall Risk to High, Low and Night',
  },
  {
    id: 'global.fall-risk-off.view',
    label: 'Set Fall Risk to Fall Only',
  },
  {
    id: 'global.contact-support.view',
    label: 'Contact Support',
  },
  {
    id: 'global.bathroom-alert-room-toggle.view',
    label: 'Bathroom Alert Room Toggle',
  },
];

export const roomsPagePermissions: PermissionWithLabel = [
  {
    id: 'virtual-care.rooms.telesitter.view',
    label: 'Clear Images',
  },
  {
    id: 'virtual-care.rooms.manage-room-assignments-all.view',
    label: 'Assign Rooms to Others',
  },
  {
    id: 'virtual-care.rooms.mute-alerts.view',
    label: 'Mute Alerts',
  },
];

export const eventReviewPagePermissions: PermissionWithLabel = [
  {
    id: 'virtual-care.event-review.time-shortcuts.view',
    label: '24/48hr Time Shortcuts',
  },
  {
    id: 'virtual-care.event-review.spotlight.view',
    label: 'Spotlight',
  },
];

export const adminPagePermissions: PermissionWithLabel = [
  {
    id: 'virtual-care.admin.basestations.view',
    label: 'Basestations',
  },
  {
    id: 'virtual-care.admin.buildings.view',
    label: 'Buildings',
  },
  {
    id: 'virtual-care.admin.floors.view',
    label: 'Floors',
  },
  {
    id: 'virtual-care.admin.units.view',
    label: 'Units',
  },
  {
    id: 'virtual-care.admin.users.view',
    label: 'Users',
  },
  {
    id: 'virtual-care.admin.rooms.view',
    label: 'Rooms',
  },
  {
    id: 'virtual-care.admin.organizations.view',
    label: 'Organizations',
  },
  {
    id: 'virtual-care.admin.beacons.view',
    label: 'Beacons',
  },
  {
    id: 'virtual-care.admin.roles.view',
    label: 'Roles & Permissions',
  },
  {
    id: 'virtual-care.admin.care-levels.view',
    label: 'Care Levels',
  },
];

// we want to show that value only when ZUI is available, and right now only few customers have it
if (import.meta.env.VITE_ZUI_DOMAIN) {
  adminPagePermissions.push({
    id: 'virtual-care.admin.api-keys.view',
    label: 'API Keys',
  });
}

export const mobilePermissions: PermissionWithLabel = [
  {
    id: 'mobile.all-current-care-team.view',
    label: 'Update Current Care Team',
  },
  {
    id: 'mobile.virtual-curtain.view',
    label: 'Virtual Curtain',
  },
  {
    id: 'mobile.level-0-alerts.view',
    label: 'View Level 0 Alerts',
  },
];

export const allPermissions: PermissionWithLabel = [
  ...globalPermissions,
  ...roomsPagePermissions,
  ...eventReviewPagePermissions,
  ...adminPagePermissions,
  ...mobilePermissions,
  {
    id: 'virtual-care.access',
    label: 'Virtual Care Access',
  },
  {
    id: 'mobile.access',
    label: 'Mobile Access',
  },
  {
    id: 'virtual-care.rooms.view',
    label: 'Rooms Page',
  },
  {
    id: 'virtual-care.event-review.view',
    label: 'Event Review Page',
  },
  {
    id: 'virtual-care.admin.view',
    label: 'Admin Page',
  },
  {
    id: 'virtual-care.tableau.view',
    label: 'View Tableau Launch Button',
  },
  {
    id: 'virtual-care.analytics.view',
    label: 'View Analytics Page',
  },
  {
    id: 'zui.access',
    label: 'ZUI Access',
  },
];
