import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';

import { fetchExportProgress } from './data-access/fetchExportProgress';
import { startVideoExport } from './data-access/startVideoExport';

import { useEventReviewStoreShallow } from '../store/EventReviewStore';

type Status = 'loading' | 'error' | 'success' | 'no-images';

type Props = {
  open: boolean;
  onClose: () => void;
};

interface State {
  status: Status | null;
  downloadUrl?: string | null;
  progress: number;
  exportId?: string | null;
  error?: string | null;
}

const DownloadModal = ({ open, onClose }: Props) => {
  const [state, setState] = useState<State>({
    status: 'loading',
    progress: 0,
  });

  const anchorRef = useRef<HTMLAnchorElement | null>(null);

  const { selectedRoom, startDate, endDate } = useEventReviewStoreShallow([
    'selectedRoom',
    'startDate',
    'endDate',
  ]);

  const { data: progressData } = useQuery({
    queryKey: ['eventReview', 'videoExportProgress', state.exportId],
    queryFn: () => fetchExportProgress(state.exportId!),
    enabled: !!state.exportId && state.status === 'loading',
    refetchInterval: 1000,
  });

  const startExport = useCallback(async () => {
    try {
      if (!selectedRoom || !startDate || !endDate) return;

      const { exportId } = await startVideoExport(
        selectedRoom.domainId,
        startDate,
        endDate,
      );

      setState((prevState) => ({
        ...prevState,
        exportId,
      }));
    } catch {
      setState((prevState) => ({
        ...prevState,
        status: 'error',
        error: 'Something went wrong while trying to download the video.',
      }));
    }
  }, [selectedRoom, startDate, endDate]);

  useEffect(() => {
    if (open) {
      startExport();
    } else {
      setState({
        status: 'loading',
        progress: 0,
      });
    }
  }, [open]);

  useEffect(() => {
    if (state.status === 'success') {
      setTimeout(() => {
        anchorRef?.current?.click();
      }, 1000);
    }
  }, [state.status]);

  useEffect(() => {
    if (progressData) {
      let status: Status = 'loading';
      if (progressData.error) status = 'error';
      else if (progressData.progress === 100 && !isNil(progressData.url))
        status = 'success';
      else if (progressData.progress === 100 && isNil(progressData.url))
        status = 'no-images';

      setState((prevState) => ({
        ...prevState,
        downloadUrl: progressData.url,
        progress: progressData.progress || 0,
        error: progressData.error,
        status,
      }));
    }
  }, [progressData]);

  return (
    <Dialog open={open}>
      <Stack direction='row'>
        <DialogTitle>Download as MP4</DialogTitle>
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            ml: 'auto',
            mr: 1,
            ':hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <Close />
        </IconButton>
      </Stack>
      <DialogContent sx={{ textAlign: 'center', minWidth: 300 }}>
        {state.status === 'loading' && (
          <>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
              <CircularProgress
                size={48}
                variant={state.progress === 0 ? 'indeterminate' : 'determinate'}
                value={state.progress}
              />
              {state.progress > 0 && (
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant='caption'
                    component='div'
                  >{`${state.progress}%`}</Typography>
                </Box>
              )}
            </Box>

            <Typography mt={3}>Encoding video...</Typography>
          </>
        )}

        {state.status === 'success' && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <DownloadIcon sx={{ fontSize: 48, mb: 4 }} />
            <Typography sx={{ mb: 4 }}>
              Downloading should start automatically. If not please click the
              link below.
            </Typography>
            <a href={state.downloadUrl || undefined} ref={anchorRef}>
              Click to download.
            </a>
          </Box>
        )}
        {state.status === 'error' && (
          <Alert severity='error'>{state.error}</Alert>
        )}
        {state.status === 'no-images' && (
          <Alert severity='warning'>
            We couldn&apos;t find the images for given period of time.
          </Alert>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DownloadModal;
