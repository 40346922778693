import { useCallback, useState } from 'react';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { MoreVert as MenuIcon } from '@mui/icons-material';
import { addMinutes, subMinutes } from 'date-fns';

import { SpotlightIcon } from '../../SVG';
import { useUpdateSelection } from '../hooks/useUpdateSelection';
import { useEventReviewStoreShallow } from '../store/EventReviewStore';

const SPOTLIGHT_MINUTES = 5;

interface Props {
  id: string;
  eventStart: Date | null;
  eventEnd: Date | null;
}

const TableRowMenu = ({ id, eventStart, eventEnd }: Props) => {
  const { selectedOrg } = useEventReviewStoreShallow(['selectedOrg']);

  const { updateStartAndEndDate } = useUpdateSelection();

  const theme = useTheme();

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const activateSpotlight = useCallback(() => {
    if (!eventStart && !eventEnd) return;

    const spotlightStart = eventStart
      ? subMinutes(eventStart, SPOTLIGHT_MINUTES)
      : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we've checked there's at least one
        subMinutes(eventEnd!, SPOTLIGHT_MINUTES);

    const spotlightEnd = eventEnd
      ? addMinutes(eventEnd, SPOTLIGHT_MINUTES)
      : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we've checked there's at least one
        addMinutes(eventStart!, SPOTLIGHT_MINUTES);

    updateStartAndEndDate(spotlightStart, spotlightEnd);
  }, [eventStart, eventEnd, updateStartAndEndDate, selectedOrg]);

  return (
    <Box>
      <IconButton
        className='table-row-menu-button'
        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
        id={`${id}-button`}
        aria-controls={menuOpen ? `${id}-menu` : undefined}
        aria-haspopup='true'
        aria-expanded={menuOpen ? 'true' : undefined}
        sx={{
          height: 28,
          width: 28,
        }}
        size='large'
      >
        <MenuIcon fontSize='medium' />
      </IconButton>
      <Menu
        id={`${id}-menu`}
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={() => setMenuAnchorEl(null)}
        MenuListProps={{ 'aria-labelledby': `${id}-button` }}
      >
        <MenuItem onClick={activateSpotlight}>
          <Stack>
            <Stack direction='row' mb={1} color='primary.main'>
              <SpotlightIcon
                width={26}
                height={26}
                fill={theme.palette.primary.main}
              />
              <Typography variant='h6' sx={{ ml: 1 }}>
                Spotlight
              </Typography>
            </Stack>
            <Typography variant='body2'>
              Automatically set the time range to {SPOTLIGHT_MINUTES} minutes
              before and after this event.
            </Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default TableRowMenu;
