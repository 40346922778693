import { Controller, useWatch } from 'react-hook-form';

import SelectUnit from './SelectUnit';

import type { Control } from 'react-hook-form';

interface Props {
  control: Control;
}

const ControlledSelectedUnit = ({ control }: Props) => {
  const selectedLevelAccess = useWatch({ name: 'levelAccess', control });

  const selectedLevelAccessBuildingIds = useWatch({
    name: 'levelAccessBuildingIds',
    control,
  });

  return (
    <Controller
      name='unitId'
      control={control}
      defaultValue={null}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => (
        <SelectUnit
          error={error}
          onChange={onChange}
          value={value}
          id={name}
          label='Unit'
          selectedLevelAccessBuildingIds={selectedLevelAccessBuildingIds || []}
          selectedLevelAccess={selectedLevelAccess}
        />
      )}
    />
  );
};

export default ControlledSelectedUnit;
