import { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { getAxiosErrorMessage } from '@inspiren-monorepo/util-axios';
import { DomainId } from '@inspiren-monorepo/util-rooms';

import { basestationsTableFields } from './constants/basestationsTableFields';
import useBasestationUpsert from './hooks/useBasestationUpsert';

import { useIsAdmin } from '../../../../hooks/useIsAdmin';
import BulkImportLink from '../../components/BulkImportLink';
import { TableBase } from '../../components/TableBase';
import { getOrgs } from '../../data-access/getOrgs';
import { getUnits } from '../../data-access/getUnits';
import EditOrgUnitRoom from '../../modals/special/EditOrgUnitRoom';
import SelectVirtualScenario from '../../modals/special/SelectVirtualScenario';

import type { BasestationFields } from './types/BasestationFieldTypes';
import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../../modals/FormModalBase';

const BasestationsTable = () => {
  const { isAdmin } = useIsAdmin();

  const { isLoading: orgsLoading, isError: orgsError } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  const { isLoading: unitsLoading, isError: unitsError } = useQuery({
    queryKey: ['units'],
    queryFn: getUnits,
  });

  const modalLoading = useMemo(
    () => orgsLoading || unitsLoading,
    [orgsLoading, unitsLoading],
  );

  const modalError = useMemo(
    () => orgsError || unitsError,
    [orgsError, unitsError],
  );

  const {
    basestations,
    basestationsError,
    basestationsLoading,
    handleAddSubmit,
    handleEditSubmit,
  } = useBasestationUpsert();

  const onEditSubmit: OnSubmitFormModal<BasestationFields> = useCallback(
    async (item) => {
      try {
        await handleEditSubmit(item);
        toast.success('Successfully updated basestation');
      } catch (error) {
        const message =
          getAxiosErrorMessage(error) ??
          `Error updating basestation${error ? `: ${error}` : ''}`;

        toast.error(message);
      }
    },
    [handleEditSubmit],
  );

  const onAddSubmit: OnSubmitFormModal<BasestationFields> = useCallback(
    async (item) => {
      try {
        await handleAddSubmit(item);
        toast.success('Successfully added new basestation');
      } catch (error) {
        const message =
          getAxiosErrorMessage(error) ??
          `Error adding basestation${error ? `: ${error}` : ''}`;

        toast.error(message);
      }
    },
    [handleAddSubmit],
  );

  // Empty optional fields will be empty strings for edit form control
  const data = useMemo(
    () =>
      (basestations || []).map(
        ({
          id,
          status = '',
          provisioningStatus = '',
          version = '',
          room = 'unassigned',
          virtual = false,
          demoSession,
        }) => ({
          id,
          // todo: rewrite that using org + buildingFloorUnit after migration to RDS
          organization:
            room && room !== 'unassigned' ? DomainId.toOrgId(room) || '' : '',
          buildingFloorUnit:
            room && room !== 'unassigned'
              ? DomainId.toBuildingFloorUnit(room) || ''
              : '',
          room: DomainId.parse(room).room || '',
          status,
          provisioningStatus,
          version,
          virtual,
          scenario: demoSession?.scenario || '',
        }),
      ),
    [basestations],
  );

  const renderModal = useCallback<RenderFormModal<BasestationFields>>(
    ({ defaultComponents, control, setValue }) => (
      <>
        {defaultComponents.id}
        <EditOrgUnitRoom control={control} setValue={setValue} />
        {defaultComponents.status}
        {defaultComponents.provisioningStatus}
        {defaultComponents.version}
        <SelectVirtualScenario control={control} />
      </>
    ),
    [],
  );

  return (
    <TableBase<BasestationFields>
      itemName='Basestation'
      sx={{
        '& .basestations.unassigned': {
          color: (theme) => theme.palette.grey[400],
          fontStyle: 'italic',
        },
      }}
      fields={basestationsTableFields}
      data={data}
      loading={basestationsLoading}
      modalLoading={modalLoading}
      modalError={modalError}
      error={basestationsError}
      onEditSubmit={onEditSubmit}
      onAddSubmit={onAddSubmit}
      extraActionButtons={isAdmin && <BulkImportLink itemName='basestations' />}
      renderModal={renderModal}
    />
  );
};

export default BasestationsTable;
