import { useQuery } from '@tanstack/react-query';

import type { SelectOptionsTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { useCurrentUser } from '../HOC/CurrentUserContextProvider';
import { Api } from '../utility/client';

export const useUnitOptions = () => {
  const { user } = useCurrentUser();

  const { data, isLoading, error } = useQuery({
    queryKey: ['unit-options', user?.domainId],

    queryFn: async () => {
      const url = '/selectOptions/v1/units';
      const res = await Api.get<SelectOptionsTypes.UnitOption[]>(url);

      return res?.data;
    },
  });

  return { data, loading: isLoading, error };
};
