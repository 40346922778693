import { useMemo } from 'react';
import { skipToken, useQuery } from '@tanstack/react-query';
import { intervalToDuration } from 'date-fns';
import { isNil } from 'lodash';

import { useOrganizationRolesMap } from '../../../hooks/useOrganizationRolesMap';
import { formatByResolvedByNameAndRoleId } from '../../../utility/helpers/helpers';
import { getEventNotifications } from '../data-access/getEventNotifs';
import { useEventReviewStoreShallow } from '../store/EventReviewStore';

import type { NotificationsRow } from '../DetailsPanel/Notifications/types/NotificationsRow';

export const useNotifications = () => {
  const { selectedOrg, selectedRoom, startDate, endDate, viewMode } =
    useEventReviewStoreShallow([
      'selectedOrg',
      'selectedRoom',
      'startDate',
      'endDate',
      'viewMode',
    ]);

  const roomId = selectedRoom?.domainId;
  const { data: roleMap = {} } = useOrganizationRolesMap(selectedOrg?.id);

  const { data, isLoading, isError } = useQuery({
    queryKey: [
      'eventReview',
      'notifications',
      roomId,
      startDate,
      endDate,
      viewMode,
    ],
    queryFn:
      roomId && startDate && endDate
        ? () =>
            getEventNotifications(
              roomId,
              startDate.toISOString(),
              endDate.toISOString(),
              viewMode === 'admin',
            )
        : skipToken,
  });

  const notifData: NotificationsRow[] = useMemo(() => {
    if (!data) return [];

    return data.map((notif) => {
      const promotedOn = !isNil(notif.promotedOn)
        ? new Date(notif.promotedOn)
        : null;

      const resolvedAt = !isNil(notif.resolvedAt)
        ? new Date(notif.resolvedAt)
        : null;

      return {
        id: notif.id,
        type: notif.type,
        createdAt: new Date(notif.id),
        resolvedBy: notif.resolvedBy,
        resolvedAt,
        fallRiskLevel: notif.fr,
        promotedOn,
        elapsed:
          promotedOn &&
          resolvedAt &&
          intervalToDuration({ start: promotedOn, end: resolvedAt }),
        resolvedByName: formatByResolvedByNameAndRoleId(
          roleMap,
          notif.resolvedByName,
          notif.resolvedByRoleId,
        ),
        source: notif.source,
        status: promotedOn ? 'promoted' : resolvedAt ? 'rejected' : 'pending',
        label: notif.label,
      };
    });
  }, [data, roleMap]);

  return { data: notifData, isLoading, isError };
};
