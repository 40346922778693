import { isRegExp } from 'lodash';

const NON_USERNAME_ORGS = [
  'ARROW',
  'DEMO',
  'MGH',
  'VALLEY',
  'ASSEMBLY',
  'TEST',
  'MW',
  'INSPIREN',
  /^E2E.*$/,
];

export const isUsernameOrg = (org?: string) => {
  if (!org) return false;
  return !NON_USERNAME_ORGS.some((pattern) =>
    isRegExp(pattern) ? pattern.test(org) : pattern === org,
  );
};
