import { Stack } from '@mui/material';
import type { GridCellClassNamePropType } from '@mui/x-data-grid-pro';
import * as yup from 'yup';

import { formatUnassigned } from '../../../beacons/helpers/formatUnassigned';
import {
  ImportOrgSelect,
  ImportUnitSelect,
} from '../../ImportTable/components';
import importUniqueValidator from '../../ImportTable/validators/importUniqueValidator';
import importUniqueRoomValidator from '../../RoomsTable/validators/importUniqueRoomValidator';
import { ImportRoomSelect } from '../components';
import { AssignmentHistory } from '../components/AssignmentHistory';
import importProperAssignmentValidator from '../validators/importProperAssignmentValidator';

import type { ImportableDataFields } from '../../ImportTable/types/importable';
import type { BasestationFields } from '../types/BasestationFieldTypes';

const dropdownSchema = yup.string().when('assign', {
  is: true,
  then: (schema) => schema.required("Pick one or un-check 'Assign'"),
});

const virtualDropdownSchema = yup.string().when('virtual', {
  is: true,
  then: (schema) => schema.required("Pick one or un-check 'Virtual'"),
});

const unassignedClassName: GridCellClassNamePropType = (params) => {
  if (params.value === '') return 'basestations unassigned';
  return '';
};

export const basestationsTableFields: ImportableDataFields<BasestationFields> =
  [
    {
      field: 'id',
      label: 'ID',
      width: 150,
      editType: 'text',
      editable: false,
      schema: yup
        .string()
        .required('You must provide an ID')
        .matches(
          /^[\da-z]+$/,
          'ID can only include lower-case letter and number characters',
        ),
      onImportCellEditValidators: [importUniqueValidator],
    },
    {
      field: 'assign',
      label: 'Assign Basestation to room',
      width: 'hidden',
      initialValue: true,
      schema: yup.boolean(),
    },
    {
      field: 'organization',
      label: 'Organization',
      width: 150,
      schema: dropdownSchema,
      valueFormatter: formatUnassigned,
      cellClassName: unassignedClassName,
      importType: 'select',
      renderImportCell: (params) => (
        <ImportOrgSelect {...params} allowEmptyValue />
      ),
    },
    {
      field: 'buildingFloorUnit',
      label: 'Building-Floor-Unit',
      width: 190,
      schema: dropdownSchema,
      valueFormatter: formatUnassigned,
      cellClassName: unassignedClassName,
      importType: 'select',
      renderImportCell: (params) => (
        <ImportUnitSelect useDomainId {...params} />
      ),
      onImportCellEditValidators: [importProperAssignmentValidator],
    },
    {
      field: 'room',
      label: 'Room',
      width: 160,
      schema: dropdownSchema,
      valueFormatter: formatUnassigned,
      cellClassName: unassignedClassName,
      renderCell: ({ id, formattedValue }) => (
        <Stack
          sx={{ width: '100%' }}
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          {formattedValue}
          <AssignmentHistory basestationId={id as string} />
        </Stack>
      ),
      importType: 'select',
      importLabel: 'Room ID',
      renderImportCell: (params) => (
        <ImportRoomSelect useDomainId {...params} />
      ),
      onImportCellEditValidators: [
        importUniqueRoomValidator(
          'Room is already assigned to other basestation',
        ),
        importProperAssignmentValidator,
      ],
    },
    { field: 'status', label: 'Status', width: 110, editType: 'text' },
    {
      field: 'provisioningStatus',
      label: 'Provisioning Status',
      width: 200,
      editType: 'text',
    },
    { field: 'version', label: 'Version', width: 120, editType: 'text' },
    {
      field: 'virtual',
      label: 'Virtual',
      initialValue: false,
      schema: yup.boolean(),
      importType: 'boolean',
      width: 80,
      flex: 0,
    },
    {
      field: 'scenario',
      label: 'Scenario',
      schema: virtualDropdownSchema,
      width: 200,
    },
  ];
