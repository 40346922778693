import { isNumber } from 'lodash';

interface Props {
  assigned: number | undefined | null;
  unit: number | undefined | null;
  building: number | undefined | null;
}

export const RenderEscalationValues = ({ assigned, unit, building }: Props) => {
  const formatValue = (value: number | undefined | null) =>
    isNumber(value) ? value : '';

  return (
    <div>
      {`${formatValue(assigned)}-${formatValue(unit)}-${formatValue(building)}`}
    </div>
  );
};
