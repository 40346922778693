import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  Check as CheckIcon,
  KeyboardArrowDown as DownArrowIcon,
} from '@mui/icons-material';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';

interface Props<T extends string = string> {
  value: T;
  options: { label: string; value: T }[];
  onChange: (value: T) => void;
  id: string;
}

export const DropdownSingleSelect = <T extends string = string>({
  value,
  options,
  onChange,
  id,
}: Props<T>) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: id,
  });

  return (
    <>
      <Button
        {...bindTrigger(popupState)}
        startIcon={<DownArrowIcon />}
        size='small'
        variant='contained'
      >
        {options.find((option) => option.value === value)?.label}
      </Button>
      <Menu {...bindMenu(popupState)}>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === value}
            onClick={() => onChange(option.value)}
          >
            {option.value === value && (
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
            )}
            <ListItemText inset={option.value !== value}>
              {option.label}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
