import { forwardRef, useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import type {
  GridEditSingleSelectCellProps,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-pro';
import {
  GridEditSingleSelectCell,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';

import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { getOrgs } from '../../../data-access/getOrgs';
import { getOptionValue, getOptionLabel } from '../helpers/getOptionData';
import { useFieldValidator } from '../hooks/useFieldValidator';

const getValueOptions = (
  orgs?: AdminTypes.OrganizationDto[],
  allowEmptyValue?: boolean,
  filteredOrgs?: string[],
) => {
  const valueOptions = [];

  if (allowEmptyValue) {
    valueOptions.push({ value: '', label: 'Unassigned' });
  }

  if (orgs && orgs.length > 0) {
    let sortedOrgs = orgs.map((e) => e.id).sort();

    if (filteredOrgs) {
      sortedOrgs = sortedOrgs.filter((orgId) => filteredOrgs.includes(orgId));
    }

    valueOptions.push(...sortedOrgs);
  }

  return valueOptions;
};

type Props = GridRenderEditCellParams<never, string> & {
  allowEmptyValue?: boolean;
  filteredOrgs?: string[];
};

const ImportOrgSelect = forwardRef((props: Props, ref) => {
  const apiRef = useGridApiContext();

  const { isLoading, data: orgs } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  const { allowEmptyValue, filteredOrgs, ...params } = props;
  const { id, error: validationError, disabled } = params;

  const valueOptions = useMemo(
    () => getValueOptions(orgs, allowEmptyValue, filteredOrgs),
    [orgs, allowEmptyValue, filteredOrgs],
  );

  const { invalidValueError } = useFieldValidator({
    skipValidation: isLoading,
    params,
    valueOptions,
  });

  const selectParams: GridEditSingleSelectCellProps = {
    ...params,
    colDef: {
      ...params.colDef,
      type: 'singleSelect',
      valueOptions,
      getOptionValue,
      getOptionLabel,
    },
    disabled: disabled || isLoading,
    startAdornment: isLoading && (
      <CircularProgress color='secondary' size={18} />
    ),
    onValueChange: () => {
      apiRef.current.setEditCellValue({
        id,
        field: 'buildingFloorUnit',
        value: '',
        debounceMs: 200,
      });

      apiRef.current.setEditCellValue({
        id,
        field: 'room',
        value: '',
        debounceMs: 200,
      });
    },
    error: invalidValueError || !!validationError,
  };

  return <GridEditSingleSelectCell {...selectParams} ref={ref} />;
});

export default ImportOrgSelect;
