import { useCallback, useMemo, useState } from 'react';
import {
  DataGridPro,
  type GridActionsColDef,
  type GridRowParams,
  type DataGridProProps,
  type GridSlots,
  type GridSlotProps,
  type GridSortDirection,
} from '@mui/x-data-grid-pro';
import { isString } from 'lodash';

import { Toolbar, type ToolbarProps } from './components/Toolbar';
import { getGridColDefsFromDataFields } from './helpers/getGridColDefsFromDataFields';

import EditModal from '../../modals/EditModal';
import TableError from '../../tables/TableError';

import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../../modals/FormModalBase';
import type { DataFields, FieldTypes } from '../../types/DataFields';

type Props<F extends FieldTypes> = {
  itemName: string;
  fields: DataFields<F>;
  data?: DataGridProProps['rows'];
  modalLoading?: boolean;
  error?: string | null | boolean;
  modalError?: string | null | boolean;
  onModalOpen?: () => void;
  onEditSubmit?: OnSubmitFormModal<F>;
  disableEditing?: boolean;
  defaultSort?: keyof F & string;
  defaultSortDirection?: GridSortDirection;
  customNoRowsText?: string;
  renderModal?: RenderFormModal<F>;
  getExtraRowActions?: GridActionsColDef['getActions'];
} & Omit<
  DataGridProProps<F>,
  | 'columns'
  | 'disableRowSelectionOnClick'
  | 'initialState'
  | 'localeText'
  | 'pageSizeOptions'
  | 'rows'
  | 'slotProps'
  | 'slots'
> &
  Pick<
    ToolbarProps<F>,
    | 'disableAddButton'
    | 'extraActionButtons'
    | 'extraTableOptions'
    | 'showAddButton'
    | 'showExportMenu'
    | 'onAddSubmit'
  >;

const TableBase = <F extends FieldTypes>(props: Props<F>) => {
  const {
    itemName,
    fields,
    data = [],
    modalLoading = false,
    error,
    modalError = null,
    onModalOpen,
    onEditSubmit,
    onAddSubmit,
    disableEditing = false,
    defaultSort,
    defaultSortDirection,
    showExportMenu,
    customNoRowsText,
    extraActionButtons,
    extraTableOptions,
    showAddButton,
    disableAddButton,
    renderModal,
    getExtraRowActions,
    ...dataGridProps
  } = props;

  const [panelAnchorEl, setPanelAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );

  const getActions = useCallback(
    (params: GridRowParams) => {
      const actions: JSX.Element[] = [];

      if (!disableEditing) {
        actions.push(
          <EditModal<F>
            itemName={itemName}
            fields={fields}
            initialValues={params.row}
            loading={modalLoading}
            error={modalError}
            onSubmit={onEditSubmit}
            onOpen={onModalOpen}
            renderModal={renderModal}
          />,
        );
      }

      if (getExtraRowActions) {
        actions.push(...getExtraRowActions(params));
      }

      return actions;
    },
    [
      disableEditing,
      itemName,
      fields,
      modalLoading,
      modalError,
      onEditSubmit,
      onModalOpen,
      renderModal,
      getExtraRowActions,
    ],
  );

  const actionColumn: GridActionsColDef = useMemo(
    () => ({
      field: 'Actions',
      type: 'actions',
      width: 50,
      hideable: false,
      resizable: false,
      getActions,
    }),
    [getActions],
  );

  const dataColumns = useMemo(
    () => getGridColDefsFromDataFields(fields),
    [fields],
  );

  const columns = useMemo(
    () => [actionColumn, ...dataColumns],
    [actionColumn, dataColumns],
  );

  const errorMessage = useMemo(() => {
    if (error === true) return '';
    if (isString(error)) return error;
    return null;
  }, [error]);

  const modalErrorMessage = useMemo(() => {
    if (modalError === true) return '';
    if (isString(modalError)) return modalError;
    return null;
  }, [modalError]);

  const toolbarProps: ToolbarProps<F> = useMemo(
    () => ({
      itemName,
      fields,
      setPanelAnchorEl,
      onAddSubmit,
      onAddOpen: onModalOpen,
      addLoading: modalLoading,
      addError: modalErrorMessage,
      showExportMenu,
      extraActionButtons,
      extraTableOptions,
      disableAddButton,
      showAddButton,
      renderModal,
    }),
    [
      itemName,
      fields,
      setPanelAnchorEl,
      onAddSubmit,
      onModalOpen,
      modalLoading,
      modalErrorMessage,
      showExportMenu,
      extraActionButtons,
      extraTableOptions,
      disableAddButton,
      showAddButton,
      renderModal,
    ],
  );

  return errorMessage ? (
    <TableError error={errorMessage} />
  ) : (
    <DataGridPro
      {...dataGridProps}
      columns={columns}
      rows={data}
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      initialState={{
        pagination: {
          paginationModel: { pageSize: 25 },
        },
        sorting: {
          sortModel: [
            { field: defaultSort || 'id', sort: defaultSortDirection || 'asc' },
          ],
        },
      }}
      slots={{
        toolbar: Toolbar as GridSlots['toolbar'],
      }}
      slotProps={{
        panel: {
          anchorEl: panelAnchorEl,
          placement: 'bottom-end',
        },
        loadingOverlay: {
          variant: 'linear-progress',
          noRowsVariant: 'skeleton',
        },
        toolbar: toolbarProps as GridSlotProps['toolbar'],
      }}
      localeText={{ noRowsLabel: customNoRowsText || 'No rows' }}
      disableRowSelectionOnClick
    />
  );
};

export default TableBase;
