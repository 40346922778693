import { useQuery } from '@tanstack/react-query';
import { sortBy } from 'lodash';
import { useWatch } from 'react-hook-form';

import { getFloors } from '../../data-access/getFloors';
import { getOrgs } from '../../data-access/getOrgs';
import ControlledDropdown from '../ControlledDropdown';

import type { UnitFieldTypes } from '../../tables/UnitsTable/types/UnitFieldTypes';
import type { Control, UseFormSetValue } from 'react-hook-form';

type Props = {
  control: Control;
  setValue: UseFormSetValue<Pick<Partial<UnitFieldTypes>, 'floorId' | 'orgId'>>;
  type: 'add' | 'edit';
};

const SelectOrgFloor = ({ control, setValue, type }: Props) => {
  const selectedOrg = useWatch({ name: 'orgId', control });

  const { isLoading: orgsLoading, data: orgs } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  const { isLoading: floorsLoading, data: floors } = useQuery({
    queryKey: ['floors'],
    queryFn: getFloors,
  });

  const loading = orgsLoading || floorsLoading;

  return (
    <>
      <ControlledDropdown
        id='orgId'
        label='Organization'
        items={(orgs || []).map((org) => org.id).sort()}
        control={control}
        disabled={loading || type === 'edit'}
        onChange={() => setValue('floorId', '')}
      />
      <ControlledDropdown
        id='floorId'
        label='Building-Floor'
        items={sortBy(
          (floors || []).filter((floor) => floor.orgId === selectedOrg),
          (floor) => floor.domainId,
        ).map((floor) => ({
          value: floor.id,
          label: floor.domainId.replace(`${selectedOrg}-`, ''),
        }))}
        control={control}
        disabled={loading || selectedOrg === '' || type === 'edit'}
      />
    </>
  );
};

export default SelectOrgFloor;
