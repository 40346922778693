import { useCallback, useMemo, useState } from 'react';
import {
  Alert,
  AlertTitle,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { DataGridPro, type GridColDef } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';

import { useOrganizationRolesMap } from '../../../hooks/useOrganizationRolesMap';
import { dateTimeFormat } from '../../../utility/helpers/time';
import { useNotificationsSent } from '../hooks/useNotificationsSent';
import { useEventReviewStoreShallow } from '../store/EventReviewStore';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 3,
  },
  {
    field: 'role',
    headerName: 'Role',
    flex: 2,
  },
  {
    field: 'receivedAt',
    headerName: 'Received At',
    type: 'dateTime',
    valueFormatter: (value) => value ? format(value, dateTimeFormat) : '',
    width: 200,
  },
];

interface NotifsSentModalProps {
  roomId: string;
  notificationId: string;
}

export const NotifsSentModal = ({
  roomId,
  notificationId,
}: NotifsSentModalProps) => {
  const { selectedOrg } = useEventReviewStoreShallow(['selectedOrg']);
  const { data: roleMap = {} } = useOrganizationRolesMap(selectedOrg?.id);

  const { data, isLoading, isError } = useNotificationsSent(
    roomId,
    notificationId,
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpenModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const rows = useMemo(() => {
    if (data) {
      return data.map((item) => ({
        id: item.id,
        name: `${item.firstName} ${item.lastName}`,
        role: roleMap[item.roleId]?.displayName,
        receivedAt: new Date(item.createdAt),
      }));
    }

    return [];
  }, [data, roleMap]);

  return (
    <>
      <Link component='button' onClick={handleOpenModal}>
        Show notifications sent
      </Link>
      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={handleCloseModal}
          fullWidth
          maxWidth='md'
        >
          <DialogTitle>
            Notifications Sent
            <IconButton
              aria-label='close'
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {isError ? (
              <Alert severity='error'>
                <AlertTitle>Error</AlertTitle>
                An error occured while loading the sent notifications.
              </Alert>
            ) : (
              <DataGridPro
                columns={columns}
                rows={rows}
                loading={isLoading}
                hideFooter
                disableRowSelectionOnClick
                disableColumnSelector
                disableColumnPinning
                initialState={{
                  density: 'compact',
                  sorting: {
                    sortModel: [{ field: 'receivedAt', sort: 'asc' }],
                  },
                }}
                slotProps={{
                  loadingOverlay: {
                    variant: 'linear-progress',
                    noRowsVariant: 'skeleton',
                  },
                }}
              />
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
