import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { isEmpty, isNil, keyBy } from 'lodash';
import toast from 'react-hot-toast';

import { getAxiosErrorMessage } from '@inspiren-monorepo/util-axios';
import {
  getUsernameFromEmail,
  isUsernameOrg,
} from '@inspiren-monorepo/util-users';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import ChangePassword from './components/ChangePassword';
import ControlledSelectLevelAccessBuildings from './components/ControlledSelectLevelAccessBuildings';
import ControlledSelectUnit from './components/ControlledSelectUnit';
import { SelectLevelAccess } from './components/SelectLevelAccess';
import SelectRole from './components/SelectRole';
import { getUsersTableFields } from './helpers/getUsersTableFields';
import useUsersUpsert from './hooks/useUsersUpsert';

import { useBuildingOptions } from '../../../../hooks/useBuildingOptions';
import { useIsAdmin } from '../../../../hooks/useIsAdmin';
import { useOrganizationRolesMap } from '../../../../hooks/useOrganizationRolesMap';
import BulkImportLink from '../../components/BulkImportLink';
import OrgHeader from '../../components/OrgHeader';
import OrgSelector from '../../components/OrgSelector';
import { TableBase } from '../../components/TableBase';
import { AdminTableWrapper } from '../../components/TableBase/AdminTableWrapper';
import { getOrgs } from '../../data-access/getOrgs';
import { useAdminUsers } from '../../hooks/useAdminUsers';
import { useUnits } from '../../hooks/useUnits';

import type { RoleOption } from './helpers/getRoleOptions';
import type { UserFields } from './helpers/getUsersTableFields';
import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../../modals/FormModalBase';

const UsersTable = () => {
  const awsOrg = import.meta.env.VITE_ORG_ID;
  const { isAdmin } = useIsAdmin();
  const [staffView, setStaffView] = useState(!isAdmin);

  const [selectedOrgId, setSelectedOrgId] = useState<string | undefined>(
    awsOrg,
  );

  const {
    isLoading: orgsLoading,
    data: orgs,
    isError: orgsError,
  } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  const selectedOrg = useMemo(
    () => (orgs || []).find((org) => org.id === selectedOrgId),
    [selectedOrgId, orgs],
  );

  const orgId = selectedOrg?.id;

  const { users, isFetching } = useAdminUsers(orgId);

  const { data: roleMap = {} } = useOrganizationRolesMap(orgId);

  const {
    isLoading: unitsLoading,
    isError: unitsError,
    data: units,
  } = useUnits();

  const unitsMap = useMemo(() => keyBy(units || [], 'id'), [units]);

  const {
    isLoading: buildingsLoading,
    isError: buildingsError,
    data: buildingOptions = [],
  } = useBuildingOptions();

  const buildingsMap = useMemo(
    () => keyBy(buildingOptions || [], 'id'),
    [buildingOptions],
  );

  const { handleAddSubmit, handleEditSubmit } = useUsersUpsert({
    orgId,
    users,
    roleMap,
    unitsMap,
  });

  const { auth0EnterpriseConnections, textMessageAlerts } = useMemo(() => {
    const currentOrg = (orgs || []).find((org) => org.id === orgId);

    return {
      auth0EnterpriseConnections:
        currentOrg?.auth0EnterpriseConnections?.filter((r) => !isEmpty(r)) ||
        [],
      auth0DbConnection: currentOrg?.auth0DbConnection,
      textMessageAlerts: currentOrg?.textMessageAlerts,
    };
  }, [orgs, orgId]);

  const handleOrgChange = useCallback(
    (
      _e: React.ChangeEvent<object>,
      newValue: AdminTypes.OrganizationDto | null,
    ) => {
      setSelectedOrgId(newValue?.id);
    },
    [],
  );

  const modalLoading = useMemo(
    () => orgsLoading || unitsLoading || buildingsLoading,
    [orgsLoading, unitsLoading, buildingsLoading],
  );

  const modalError = useMemo(
    () => orgsError || unitsError || buildingsError,
    [orgsError, unitsError, buildingsError],
  );

  const renderModal: RenderFormModal<UserFields> = useCallback(
    ({ control, defaultComponents, fields, type, setValue, initialValues }) => (
      <>
        {isAdmin && (
          <>
            <OrgHeader
              displayName={selectedOrg?.displayName}
              id={selectedOrg?.id}
            />
            {defaultComponents.id}
          </>
        )}
        {defaultComponents.firstName}
        {defaultComponents.lastName}
        {orgId && isUsernameOrg(orgId) && defaultComponents.username}
        {defaultComponents.email}
        {defaultComponents.mobilePhone}
        {type === 'add' && defaultComponents.password}
        {type === 'edit' ? (
          <SelectRole
            control={control}
            items={fields.role.options as RoleOption[]}
          />
        ) : (
          defaultComponents.role
        )}
        <SelectLevelAccess control={control} roleMap={roleMap} />
        <ControlledSelectLevelAccessBuildings control={control} />
        <ControlledSelectUnit control={control} />
        {type === 'edit' &&
          !auth0EnterpriseConnections.includes(
            initialValues?.auth0Connection || '',
          ) && <ChangePassword control={control} setValue={setValue} />}
      </>
    ),
    [selectedOrg, orgId, isAdmin, roleMap, auth0EnterpriseConnections],
  );

  const fields = useMemo(
    () =>
      getUsersTableFields(
        isAdmin,
        roleMap,
        buildingsMap,
        unitsMap,
        orgId,
        textMessageAlerts,
        staffView,
      ),
    [
      isAdmin,
      orgId,
      roleMap,
      textMessageAlerts,
      staffView,
      unitsMap,
      buildingsMap,
    ],
  );

  const usersData = useMemo(
    () =>
      users
        // TODO: when moving to server side staff view should be filtered on the server
        .filter((user) => !staffView || user.showInStaffView)
        .map((user) => {
          const usernameFromEmail = isUsernameOrg(orgId)
            ? getUsernameFromEmail(user.email, orgId)
            : null;

          return {
            id: user.domainId,
            userId: user.id,
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            role: user.roleId || '',
            unitId: user.unitId || null,
            email: (!usernameFromEmail && user.email) || '',
            username: user.username || usernameFromEmail || '',
            password: '',
            levelAccess: user.levelAccess || '',
            levelAccessBuildingIds: user.levelAccessBuildingIds || [],
            beacon: user.beacon || '',
            mobilePhone: user.mobilePhone,
            mobilePhoneVerified: user.mobilePhoneVerified,
            auth0Connection: user.auth0Connection,
            lastActivityAt: user.lastActivityAt,
          };
        }),
    [users, staffView],
  );

  const onEditSubmit: OnSubmitFormModal<UserFields> = async (params) => {
    try {
      await handleEditSubmit(params);
      toast.success('Successfully edited the user');
    } catch (error) {
      const errMsg = getAxiosErrorMessage(error) || 'failed to edit user';
      toast.error(`Error: ${errMsg}`);
    }
  };

  const onAddSubmit: OnSubmitFormModal<UserFields> = async (params) => {
    try {
      await handleAddSubmit(params);
      toast.success('Successfully added the user');
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 409) {
        toast.error('Error: user already exists');
      } else {
        const errMsg = getAxiosErrorMessage(error) || 'failed to add new user';
        toast.error(`Error: ${errMsg}`);
      }
    }
  };

  return (
    <AdminTableWrapper>
      {isAdmin && (
        <OrgSelector
          orgs={orgs ?? []}
          loading={orgsLoading}
          value={selectedOrg || null}
          onChange={handleOrgChange}
        />
      )}
      <TableBase<UserFields>
        key={`users-table-${staffView ? 'staff' : 'all'}`}
        itemName='User'
        fields={fields}
        data={usersData || []}
        loading={isFetching}
        modalLoading={modalLoading}
        modalError={modalError}
        error={false}
        onEditSubmit={onEditSubmit}
        onAddSubmit={onAddSubmit}
        customNoRowsText={
          isAdmin && isNil(selectedOrg) ? 'No organization selected' : undefined
        }
        defaultSort={staffView ? 'status' : undefined}
        defaultSortDirection={staffView ? 'desc' : undefined}
        extraActionButtons={
          isAdmin && (
            <BulkImportLink
              itemName='users'
              disabled={!selectedOrg?.id}
              urlSuffix={selectedOrg?.id}
            />
          )
        }
        extraTableOptions={
          // TODO: Use DropdownSingleSelect component instead, similiar to the RoomsTable
          // This change probably requires customer comms
          <FormControlLabel
            id='staff-view-switch'
            label='Enable Staff Status View'
            checked={staffView}
            onChange={(_e, checked) => setStaffView(checked)}
            slotProps={{ typography: { variant: 'body2' } }}
            control={<Switch />}
          />
        }
        disableAddButton={!orgId}
        renderModal={renderModal}
      />
    </AdminTableWrapper>
  );
};

export default UsersTable;
