import { useMemo } from 'react';

import type { TelesittingTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

const tabs = [
  'basestations',
  'beacons',
  'buildings',
  'care-levels',
  'floors',
  'organizations',
  'rooms',
  'units',
  'users',
] as const;

export type AdminTab = (typeof tabs)[number] | 'api-keys';

export const useAccessibleAdminTabs = (
  user: TelesittingTypes.User | null | undefined,
) =>
  useMemo((): AdminTab[] => {
    const accessibleTabs: AdminTab[] = tabs.filter((tab) =>
      user?.ability?.can('view', `virtual-care.admin.${tab}`),
    );

    const zuiApiUrl = import.meta.env.VITE_ZUI_API_URL;

    // we want to show that tab only when ZUI is available, and right now only few customers have it
    if (
      !!zuiApiUrl &&
      user?.ability?.can('view', 'virtual-care.admin.api-keys')
    ) {
      accessibleTabs.push('api-keys');
    }

    return accessibleTabs;
  }, [user]);
