import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import { useEventReviewStoreShallow } from '../store/EventReviewStore';

export const AdminViewToggle = () => {
  const { viewMode, setViewMode } = useEventReviewStoreShallow([
    'viewMode',
    'setViewMode',
  ]);

  return (
    <ToggleButtonGroup
      value={viewMode}
      onChange={(_event, newValue) => setViewMode(newValue)}
      exclusive
      color='primary'
      size='small'
    >
      <ToggleButton value='admin' sx={{ width: 60 }}>
        Admin
      </ToggleButton>
      <ToggleButton value='staff' sx={{ width: 60 }}>
        Staff
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
